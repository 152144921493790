<template>
  <div class="">
    <div class="px-4 space-y-4">
      <PlanTabs :tabs="tabs" :active="selected" @change="selected = $event" />
    </div>

    <div class="mt-7 bg-white border-[#DFE4E8] border-y">
      <table class="w-full table-fixed text-left">
        <thead>
          <tr>
            <th class="px-2 py-3 border-r border-[#DFE4E8]" colspan="2">
              <div class="rounded bg-[#F3F5F8] pr-4">
                <select
                  name="name"
                  v-model="firstIndex"
                  @change="firstIndex === secondIndex && (secondIndex = firstIndex ? 0 : 1)"
                  class="block w-full rounded border-0 py-2 pl-3 text-[#292C2E] text-base bg-[#F3F5F8] font-normal outline-none"
                >
                  <option v-for="(rate, index) in rates" :key="index" :value="index">
                    {{ rate.title }}
                  </option>
                </select>
              </div>
            </th>
            <th class="px-2 py-3" colspan="2">
              <div class="rounded bg-[#F3F5F8] pr-4">
                <select
                  name="name"
                  v-model="secondIndex"
                  class="block w-full rounded border-0 py-2 pl-3 pr-6 text-[#292C2E] text-base bg-[#F3F5F8] font-normal outline-none"
                >
                  <template v-for="(rate, index) in rates" :key="index">
                    <option v-if="index !== firstIndex" :value="index">
                      {{ rate.title }}
                    </option>
                  </template>
                </select>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colspan="2"
              v-for="(rate, index) in [rates[firstIndex], rates[secondIndex]]"
              :key="index"
              class="px-2 py-3 first:border-r border-[#DFE4E8] border-t"
            >
              <div v-if="rate.isPremium" class="text-sm font-bold uppercase text-[#D88200] text-center">{{ rate.title }}</div>
              <div
                v-else
                class="text-sm font-bold uppercase text-[#292C2E] flex justify-between items-center gap-0.5"
              >
                {{ rate.title }}

                <span
                  v-if="(!index && firstIndex === rates.length - 2) || (index && secondIndex === rates.length - 2)"
                  class="text-white text-[10px] leading-3 px-1.5 py-1 uppercase bg-[#008AFC] rounded"
                >
                  Recommended
                </span>
                <span v-else-if="rate.safePercent" class="text-white text-[10px] leading-3 px-1.5 py-1 uppercase bg-[#149911] rounded"> {{ rate.safePercent }}%</span>
              </div>
              <div class="items-baseline gap-x-1 text-gray-900 text-center">
                <template v-if="rate.isPremium">
                  <span class="text-xl font-bold text-[#292C2E]">Let’s talk</span>
                </template>
                <template v-else>
                  <span class="text-xl font-bold text-[#292C2E]">{{ rate.priceMonth }}</span>
                  <span class="text-xl font-bold text-[#292C2E]"> /month</span>
                </template>
              </div>
              <div v-if="rate.isPremium && selected === 1" class="text-[#676C71] text-xs mt-1 text-center">Personal billing</div>
              <template v-else>
                <div v-html="rate.priceOld" class="text-[#676C71] text-xs mt-1 text-center"></div>
              </template>
              <div class="mt-2 text-center">
                <Btn
                  v-if="rate.isPremium"
                  as="a"
                  href="mailto:info@goglobal.world"
                  size="xs"
                  theme="black"
                  class="!inline-block max-w-max"
                >
                  Contact us
                </Btn>
                <template v-else-if="hasTariff">
                    <span
                        class="flex max-w-max mx-auto p-3 gap-1.5 text-sm leading-4 text-[#292C2E] font-bold rounded cursor-pointer"
                        @click="$emit('manage')"
                        :class="
                          index === rates.length - 2
                            ? 'bg-[#008AFC] text-white'
                            : 'border border-[#292C2E]'
                        "
                    >
                      Manage
                    </span>
                </template>
                <template v-else>
                  <Btn
                      size="xs"
                      class="!inline-block max-w-max"
                      @click="$emit('subscribeWithTrial', rate)"
                      :class="[
                        (!index && firstIndex === rates.length - 2) || (index && secondIndex === rates.length - 2)
                          ? 'bg-[#008AFC] text-white'
                          : 'border border-[#292C2E] !bg-white !text-[#292C2E]',
                      ]"
                  >
                    Try free for 3 days
                  </Btn>
                  <span
                      class="mt-2 inline-block underline text-sm font-bold text-[#292C2E] flex-none"
                      @click="$emit('subscribeWithoutTrial', rate)"
                  >
                    or buy now
                  </span>
                </template>
              </div>
            </td>
          </tr>
          <tr v-for="(section, sectionIdx) in info[userRole]" :key="sectionIdx">
            <th
              colspan="2"
              scope="row"
              class="py-3 px-2 text-base font-medium first:border-r border-[#DFE4E8] border-t"
            >
              <div class="absolute inset-x-0 -mt-3 h-px bg-[#DFE4E8] -z-20" />
              {{ section.name }}
              <p
                v-if="section.label"
                class="mt-1 text-sm text-[#676C71] font-normal"
              >
                {{ section.label }}
              </p>
            </th>
            <td
              v-for="(rate, index) in [rates[firstIndex], rates[secondIndex]]"
              :key="index"
              class="py-3 px-2 border-r border-[#DFE4E8] border-t last:border-r-0"
            >
              <div
                v-if="typeof section.tiers[rate.type] === 'string'"
                class="text-center text-base font-medium"
              >
                <div v-html="section.tiers[rate.type]" />
              </div>
              <template v-else>
                <img
                  v-if="section.tiers[rate.type] === true"
                  class="mx-auto w-4"
                  src="/src/assets/images/icons/check-blue.svg"
                  alt=""
                />
                <span class="sr-only">{{
                    section.tiers[rate.type] === true
                      ? 'Included'
                      : 'Not included'
                  }}
                  in {{ rate.title }}</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import PlanTabs from '@/screens/tariffs-plans/components/PlanTabs.vue';
import Btn from '@/components/Btn.vue';
import { computed, ref } from "vue";

const props = defineProps({
  ratesList: { type: Array, default: [] },
  info: { type: Object, default: () => ({}) },
  userRole: { type: String },
  hasTariff: { type: Boolean, default: false },
  tabs: { type: Array, default: [] }
});

defineEmits({
  manage: null,
  subscribeWithTrial: null,
  subscribeWithoutTrial: null,
});

const selected = ref(1);

const rates = computed(() => props.ratesList[selected.value ? 0 : 1]);

const firstIndex = ref(rates.value.length - 2);
const secondIndex = ref(0);
</script>
