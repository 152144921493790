<template>
  <div
    class="rounded-xl bg-white relative"
    :class="
      isRecommended
        ? 'border-2 border-[#008AFC]'
        : 'border border-[#DFE4E8]'
    "
  >
    <div
      v-if="isRecommended"
      class="uppercase bg-[#008AFC] py-1 px-1.5 rounded text-xs text-white font-bold absolute -top-3 left-1/2 -translate-x-1/2"
    >
      Recommended
    </div>
    <div class="p-4 lg:p-6 text-center border-b border-[#DFE4E8] lg:h-[240px]">
      <div
        class="text-xl leading-8 font-bold uppercase"
        :class="plan.isPremium ? 'text-[#D88200]' : 'text-[#292C2E]'"
      >
        {{ plan.title }}
      </div>
      <div class="mt-2 text-[28px] leading-8 text-[#292C2E] font-bold">
        <template v-if="plan.isPremium">Let’s talk</template>
        <template v-else>
          <span>{{ plan.priceMonth }}</span>
          <span class="ml-2">/ month</span>
        </template>
      </div>
      <div v-if="plan.isPremium && annual" class="text-[#676C71] mt-1">Personal billing</div>
      <template v-else>
        <div class="text-[#676C71] mt-1">
          <span v-html="plan.priceOld"></span>
          <span v-if="plan.safePercent" class="inline-flex rounded bg-[#149911] py-0.5 px-1.5 ml-1 text-[10px] leading-[14px] font-bold text-white" style="vertical-align: 2px;"> {{ plan.safePercent }}%</span>
        </div>
      </template>
      <a
        v-if="plan.isPremium"
        href="mailto:info@goglobal.world"
        class="mt-6 flex mx-auto justify-center py-3 px-9 gap-1.5 w-[66%] text-sm leading-4 text-[#292C2E] font-bold rounded bg-[#292C2E] text-white"
      >
        Contact us
        <img src="/src/assets/images/icons/arrow-right-white.svg" alt="" />
      </a>
      <template v-else-if="hasTariff">
        <span
            class="mt-6 flex mx-auto justify-center p-3 gap-1.5 text-sm w-[66%] leading-4 text-[#292C2E] font-bold rounded cursor-pointer"
            @click="$emit('manage')"
            :class="
              isRecommended
                ? 'bg-[#008AFC] text-white'
                : 'border border-[#292C2E]'
            "
        >
          Manage
        </span>
      </template>
      <template v-else>
        <span
            class="mt-6 flex justify-center mx-auto p-3 gap-1.5 text-sm w-[66%] leading-4 text-[#292C2E] font-bold rounded cursor-pointer"
            @click="$emit('subscribeWithTrial')"
            :class="
              isRecommended
                ? 'bg-[#008AFC] text-white'
                : 'border border-[#292C2E]'
            "
        >
          Try free for 3 days
          <img
              v-if="isRecommended"
              src="/src/assets/images/icons/arrow-right-white.svg"
              alt=""
          />
          <img
              v-else
              src="/src/assets/images/icons/arrow-right-black.svg"
              alt=""
          />
        </span>
        <span
            class="inline-flex mt-2 text-base font-bold text-[#292C2E] underline cursor-pointer"
            @click="$emit('subscribeWithoutTrial')"
        >or buy now</span>
      </template>
    </div>
    <div class="p-4 lg:p-6">
      <p
        v-if="subtitleFirst"
        class="mb-4 text-base font-bold text-[#292C2E]"
      >
        {{ subtitleFirst }}
      </p>
      <template v-if="plan.isPremium">
        <p v-if="plan.isPremium">This plan is for members who would like individual support and more personalized matches.</p>
        <p class="my-4 text-base font-bold text-[#292C2E]">We provide every premium member with:</p>
      </template>
      <ul class="space-y-3">
        <li
          class="flex gap-1 items-start"
          v-for="(list, indexList) in plan.lists"
          :key="indexList"
        >
          <img src="/src/assets/images/icons/check-blue.svg" alt="" />
          <div>
            <div
              class="font-medium text-base text-[#292C2E] flex items-center gap-1"
            >
              <p v-html="list.text" />
              <img
                v-if="list.isVerified"
                class="inline-flex"
                src="/src/assets/images/profile-desktop/check-verification.svg"
                alt=""
              />
            </div>
            <p class="text-sm text-[#676C71]">{{ list.subtext }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import useCurrentPlan from "@/compositions/useCurrentPlan.js";
import { computed } from "vue";

defineProps({
  plan: {
    type: Object,
    default: () => ({}),
  },
  subtitleFirst: {
    type: String,
  },
  isRecommended: {
    type: Boolean,
    default: false
  },
  hasTariff: {
    type: Boolean,
    default: false
  },
  annual: {
    type: Boolean,
    default: false
  }
});

defineEmits({
  manage: null,
  subscribeWithTrial: null,
  subscribeWithoutTrial: null,
});
</script>
